<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            冰山數據Partner
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          欢迎您的加入! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          您已经成功注册成为冰山數據的合作伙伴，我们将会在有合适的数据采集或标注项目时通过您的注册邮箱与您取得联系，请随时关注您的邮箱，并确保我们的邮件不会被您的邮箱服务商误判为垃圾邮件。
        </b-card-text>


        <b-card-text class="mb-2">
          同时，我们也推荐您关注我们的<a style="color:#7367f0 !important" @click="toWebsite">官方网站</a>，以及我们的微信公众号，以便及时获取我们的最新动态。
        </b-card-text>

        <div style="overflow:hidden" class="mb-2">
          <img style="height: 100%; width: 100%;" :src="qrCodeSrc">
        </div>

        <b-card-title class="mb-1">
          完善您的简历
        </b-card-title>
        <b-card-text class="mb-2">
          为了让我们了解到您的更多信息和优势，请完善您的简历，如果您不希望透露某些个人信息，可以选择忽略，所有字段均非必填项，并且您可以随时修改您的简历。
        </b-card-text>

        <b-form-group
          label="姓名"
          label-for="name"
        >
          <b-form-input
            id="name"
            placeholder=""
            v-model="name"
          />
        </b-form-group>

        <b-form-group
          label="年龄"
          label-for="age"
        >
          <v-select
            v-model="age"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :options="ageOptions"
            :reduce="option => option.value"
        />
        </b-form-group>

        <b-form-group
          label="性别"
          label-for="gender"
        >
          <v-select
            v-model="gender"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :options="genderOptions"
            :reduce="option => option.value"
        />
        </b-form-group>

        <b-form-group
          label="教育程度"
          label-for="education"
        >
          <v-select
            v-model="education"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :options="educationOptions"
            :reduce="option => option.value"
        />
        </b-form-group>

        <b-form-group
          label="职业"
          label-for="job"
        >
          <v-select
            v-model="job"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :options="jobOptions"
            :reduce="option => option.value"
        />
        </b-form-group>

        <b-button
          class="mt-1"
          variant="primary"
          block
          @click="saveInfo"
        >
              保存
          </b-button>

        <div class="divider my-2">
          <div class="divider-text">
          </div>
        </div>

        <b-button
          variant="outline-danger"
          block
          @click="signOut"
        >
              退出登录
          </b-button>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormTextarea,BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormTextarea
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      genderOptions:[
        { value: 'male', text: '男'},
        { value: 'female', text: '女'},
      ],
      ageOptions: [
        { value: '18-25', text: '18-25' },
        { value: '26-35', text: '26-35' },
        { value: '36-45', text: '36-45' },
        { value: '46-55', text: '46-55' },
        { value: '56-65', text: '56-65' },
        { value: '66+', text: '66+' },
      ],
      educationOptions: [
        { value: 'primary', text: '小学'},
        { value: 'junior', text: '初中'},
        { value: 'highschool', text: '高中'},
        { value: 'college', text: '大学专科'},
        { value: 'university', text: '大学本科' },
        { value: 'master', text: '硕士' },
        { value: 'doctor', text: '博士' },
        { value: 'other', text: '其他'}
      ],
      jobOptions:[
        { value:'student', text:'学生'},
        { value:'stuff',text:'企业员工'},
        { value:'self-employed',text:'自由职业者'},
        { value:'other',text:'其他'}
      ],
      name:'',
      job:'',
      age:'',
      gender:'',
      education:'',
      qrCodeSrc: require('/public/QRCODE.png'),
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  methods:{
    toWebsite(){
      window.open('https://www.markhk.cn', '_blank')
    },
    signOut(){
      localStorage.removeItem('userData')
      localStorage.removeItem('jwt')
      this.$router.push({ name: 'auth-login-partner' })
    },
    saveInfo(){
      var info = {
        "name":this.name,
        "age":this.age,
        "job":this.job,
        "education":this.education,
        "gender":this.gender,
      }
      this.$axios.post('/partner/individuals/update_info',info).then((response)=>{
        if (response.data.status == "ok"){
          this.getInfo()
        }
      }
      )
    },
    getInfo(){
      this.$axios.get('/partner/individuals/get_info').then(resp =>{
        var userInfo = resp.data.data.user_info
        this.name = userInfo.name
        this.age = userInfo.age
        this.job = userInfo.job
        this.education = userInfo.education
        this.gender = userInfo.gender
      })
    }
  },
  created(){
    this.getInfo()
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
